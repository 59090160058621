import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";

const putNewClubRegistrationCartItemData = (personId, orgUnitId, orgUnitCode, clubRegObj, state, setState) => {
  if (personId > 0) {
    const api = initApi(API_NAMES.CLUB, state, setState);
    const personIdForUrl = encodeURIComponent(personId);
    const orgUnitIdForUrl = encodeURIComponent(orgUnitId);
    const url = `/UsasClub/cartitem/${personIdForUrl}/orgunit/${orgUnitIdForUrl}/${orgUnitCode}`;

    return api?.executeArray ? api.executeArray(url, 'PUT', clubRegObj) : null;
  }
};

const NewClubRegistrationPaymentData = {
  putNewClubRegistrationCartItemData
};

export default NewClubRegistrationPaymentData;