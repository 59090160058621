import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "../../../../common/wrappers/ReactRouterDom";

import { createNewWorkflowObj } from '../utils/WorkflowsUtils';

import { navLinks } from '../../../UseNavLinks';

import useLearnToSwimRegistrationData from "../../../state/workflow/learnToSwimRegistration/UseLearnToSwimRegistrationData";

import useEmbeddedReportData from "../../../../common/state/embeddedReport/UseEmbeddedReportData";

import Constants from '../../../../common/utils/Constants';

const INITIAL_STATE = {
  learnToSwimReg: {}
};

const INITIAL_VIEW_STATE = {
  reportParameters: { LtsProviderId: '' },
  routeName: '',
  isLoading: false,
  reportViewerType: '',
  reportName: '',
  showReport: false
};

const useLearnToSwimRegistrationApproval = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { ltsProviderRegistrationState, getLTSProviderRegistrationWorkflow, putLTSProviderRegistrationWorkflow
  } = useLearnToSwimRegistrationData();
  const { getEmbeddedReportListForRoute } = useEmbeddedReportData();
  const [state, setState] = useState(INITIAL_STATE);
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);

  const onRunLearnToSwimWorkflowReport = () => {
    setViewState({
      ...viewState,
      reportParameters: { LtsProviderId: state.learnToSwimReg.learnToSwimProviderId },
      routeName: 'WORKFLOWS_LEARN_TO_SWIM_REGISTRATION_APPROVALS',
      isLoading: true,
      reportViewerType: 'ActiveReports',
      reportName: 'New LTS Application'
    });
  };

  const onApprovedClicked = () => {
    const workflowObjCopy = JSON.parse(JSON.stringify(ltsProviderRegistrationState.objData));

    putLTSProviderRegistrationWorkflow(state.learnToSwimReg.workflowId,
      createNewWorkflowObj(workflowObjCopy, state.learnToSwimReg.workflowStepId, Constants.WORKFLOW_STATUS_APPROVED,
        '', 0));
  };

  const onRejectClicked = () => {
    const workflowObjCopy = JSON.parse(JSON.stringify(ltsProviderRegistrationState.objData));

    putLTSProviderRegistrationWorkflow(state.learnToSwimReg.workflowId,
      createNewWorkflowObj(workflowObjCopy, state.learnToSwimReg.workflowStepId, Constants.WORKFLOW_STATUS_REJECTED,
        '', 0));
  };

  const onBackClicked = () => {
    navigate(navLinks.LEARN_TO_SWIM_REGISTRATION);
  };

  useEffect(() => {
    if (viewState.routeName) {
      const reportListPromise = getEmbeddedReportListForRoute(viewState.routeName);
      if (reportListPromise) {
        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const reportIdx = stateObj.arrayData.findIndex(x => x.reportName === viewState.reportName);
            setViewState({
              ...viewState,
              isLoading: false,
              showReport: stateObj.arrayData.length > 0,
              reportInfoId: stateObj.arrayData.length === 1 ? stateObj.arrayData[reportIdx].reportInfoId : -1,
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showReport: false,
              reportInfoId: -1
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showReport: false,
            reportInfoId: -1
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName]);

  useEffect(() => {
    if (location.state?.learnToSwimReg?.workflowId > 0) {
      setState({
        ...state,
        learnToSwimReg: location.state.learnToSwimReg
      });

      getLTSProviderRegistrationWorkflow(location.state.learnToSwimReg.workflowId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ltsProviderRegistrationState.isSaved === true) {
      navigate(navLinks.LEARN_TO_SWIM_REGISTRATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ltsProviderRegistrationState.isSaved]);

  return {
    isSaving: ltsProviderRegistrationState.isSaving,
    isLoading: ltsProviderRegistrationState.isObjLoading || viewState.isLoading === true,
    learnToSwimRegObj: state.learnToSwimReg,
    ltsProviderRegistrationState,
    onApprovedClicked,
    onRejectClicked,
    onBackClicked,
    INITIAL_VIEW_STATE,
    viewState,
    setViewState,
    onRunLearnToSwimWorkflowReport
  };
};

export default useLearnToSwimRegistrationApproval;