import React, { Fragment } from 'react';

import useBasicKpiDetail from './UseBasicKpiDetail';

import Headings from '../../../../../common/components/headings/Headings';
import ReportListAsButton from '../../../../../common/components/embeddedReports/ReportListAsButton';

import global from '../../../../../common/components/GlobalStyle.module.css';

// TEMP FIX for user story 4669 
const MEASURE_NAME_ONLINE_EDUCATION_FOR_PARENTS = 'Online Safe Sport Education for Parents';
const MEASURE_NAME_ONLINE_EDUCATION_FOR_YOUTH = 'Online Safe Sport Education for Athletes';
const SAFE_SPORT_REPORT_ROUTE_NAME = 'WORKFLOWS_SAFE_SPORT_TASK_APPROVALS';

const Row = ({ row }) => (
  <div className="row usas-extra-top-margin">
    {Array.isArray(row) && row.length > 0 && row.map((field, i) => (
      <div key={i} className="col-xs-12 col-sm-6">
        <Headings.H5>{field.label}: &nbsp; &nbsp;
          <span className={global.ReadOnlyValue}>{field.value}</span>
        </Headings.H5>
      </div>
    ))}
  </div>
);

const ReadOnlyRows = ({ reportFields }) => (
  <Fragment>
    {Array.isArray(reportFields) && reportFields.length > 0 && reportFields.map((row, i) => (
      <Row key={i} row={row} />
    ))}
  </Fragment>
);

const BasicKpiDetail = ({ orgUnitId, categoryMeasure, kpiMeasureState }) => {
  const {
    kpiReportFieldsState
  } = useBasicKpiDetail(kpiMeasureState);

  return (
    <Fragment>
      {(categoryMeasure?.measureName === MEASURE_NAME_ONLINE_EDUCATION_FOR_PARENTS || categoryMeasure?.measureName === MEASURE_NAME_ONLINE_EDUCATION_FOR_YOUTH) &&
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className='col-xs-12'>
            <ReportListAsButton
              listTitle='KPI Progress Reports'
              routeName={SAFE_SPORT_REPORT_ROUTE_NAME}
              reportParameters={{ categoryMeasureId: categoryMeasure.categoryMeasureId, orgUnitId: orgUnitId }} />
          </div>
        </div>
      }
      <ReadOnlyRows reportFields={kpiReportFieldsState} />
    </Fragment>
  );
};

export default BasicKpiDetail;