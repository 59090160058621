import React, { Fragment } from 'react';

import EditIcon from '../../../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ learnToSwimReg, onRegistrationClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{learnToSwimReg.entityName || ''}</span>&nbsp;
      <button
        className={global.IconButton}
        type="button"
        onClick={e => onRegistrationClicked(e, learnToSwimReg)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Business Legal Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{learnToSwimReg.entityName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Trade Name/DBA</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{learnToSwimReg.tradeName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Contact</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{learnToSwimReg.contactFirstName || <span>&nbsp;</span>} {learnToSwimReg.contactLastName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Contact Email</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{learnToSwimReg.contactEmail || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Submission Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{learnToSwimReg.submissionDate ? formatDate(learnToSwimReg.submissionDate) : <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const LearnToSwimRegistrationGridSmall = ({ data, isLoading, onRegistrationClicked }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-lg hidden-md'].join(' ')}>
      {isLoading === true
        ? (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
          </div>
        )
        : data.length > 0
          ? data.map((learnToSwimReg, i) => <GridRow key={i} learnToSwimReg={learnToSwimReg} onRegistrationClicked={onRegistrationClicked} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>&nbsp;</div>
              <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Learn To Swim Provider Registrations</div></div>
            </div>
          )
      }
    </div>
  </Fragment>
);

export default LearnToSwimRegistrationGridSmall;