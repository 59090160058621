import { useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';
import NewClubRegistrationPaymentData from './NewClubRegistrationPaymentData';

const INITIAL_NEW_CLUB_REGISTRATION_CART_STATE = {
  ...BASIC_INITIAL_STATE
};

const useNewClubRegistrationPaymentData = () => {  
  const [newClubRegistrationCartState, setNewClubRegistrationCartState] = useState(INITIAL_NEW_CLUB_REGISTRATION_CART_STATE);

  const putNewClubRegistrationCartItem = (personId, orgunitId, orgUnitCode, clubRegObj) => {
    return NewClubRegistrationPaymentData.putNewClubRegistrationCartItemData(personId, orgunitId, orgUnitCode, clubRegObj, newClubRegistrationCartState, setNewClubRegistrationCartState);
  };

  return {
    newClubRegistrationCartState,
    putNewClubRegistrationCartItem
  }
}

export default useNewClubRegistrationPaymentData;
