import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../../../UseNavLinks';

import UseNewClubRegistrationData from '../../../state/workflow/newClubRegistration/UseNewClubRegistrationData';

import Constants from '../../../../common/utils/Constants';

const useNewClubRegistration = (approvalsPagePath) => {
  const navigate = useNavigate();
  const { newClubRegistrationState, getNewClubRegistration, getHQNewClubRegistration } = UseNewClubRegistrationData();

  const onRegistrationClicked = (e, clubReg) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(approvalsPagePath, { state: { clubReg } });
  };

  useEffect(() => {
    if (newClubRegistrationState.isArrayLoaded !== true && approvalsPagePath) {
      if (approvalsPagePath === navLinks.HQ_NEW_CLUB_REGISTRATION_APPROVALS) {
        getHQNewClubRegistration();
      } else {
        getNewClubRegistration();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newClubRegistrationState.isArrayLoaded, approvalsPagePath]);

  return {
    Constants,
    newClubRegistrationState,
    onRegistrationClicked
  };
};

export default useNewClubRegistration;